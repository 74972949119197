import React from "react";
import { Row, Col, Container } from "react-bootstrap";
// import profilePic from "../images/vrtbt_title_16x9.png";
import trailer1 from "../videos/Planet_Siege_Gameplay_Trailer_1.mp4";
// import banner from "../images/PlanetSiege_Banner.png";

const PlanetSiegeHeader = () => {
  return (

    // IMPORTANT class must be used on the image element not the containter (i.e. div)
    <div>
      <div className="game-banner-container">
        <div className="game-banner-background">
          <h1 className="game-banner-text">Planet Siege</h1>
          {/* <img src={banner} className="game-banner" /> */}
        </div>

        {/* Disable right click */}
          <div onContextMenu={(e) => e.preventDefault()}>
            <video className="game-banner-video" loop autoPlay muted >
              <source src={trailer1} type="video/mp4" />
            </video>
          </div>
      </div>
    </div>
  );
};

export default PlanetSiegeHeader;

import { ReactElement } from "react";
import {
  Button,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Carousel,
  Container,
} from "react-bootstrap";
import "../App.scss";

const CarouselItems = [
  <Carousel.Item key="0">
    <img
      className="d-block w-100"
      src={require("../images/MindFuel_Lobby_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="1">
    <img
      className="d-block w-100"
      src={require("../images/MindFuel_TheIsland_1_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="2">
    <img
      className="d-block w-100"
      src={require("../images/MindFuel_TheIsland_2_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="3" interval={12000}>
    <img
      className="d-block w-100"
      src={require("../images/MindFuel_TheStream_1_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="4" interval={12000}>
    <img
      className="d-block w-100"
      src={require("../images/MindFuel_TheStream_2_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
];

export default function MindFuelCard(): ReactElement {
  return (
    <div>
      <Card className="card-custom">
        <Carousel>{CarouselItems}</Carousel>
        <Card.Body>
          <Card.Title>Mind Fuel</Card.Title>
          <Card.Text>
            MindFuel is a mental health VR app that I created with a team of 6
            for the 2022 DeepWell Mental Health Game Jam.
          </Card.Text>
          <Card.Text>
            Users explore several therapeutic areas. In one area, a single user
            explores several guided meditations in a serene environment.
          </Card.Text>
          <Card.Text>
            The second area is a multi-user experience where users stand along a
            stream together and share their intrusive thoughts in an anonymous
            environment. Users grab a thought container and speak their
            intrusive thoughts (e.g. fears, sadness, anxieties). The user drops
            their thought into the stream and watches as it floats away.
          </Card.Text>
          <ButtonToolbar className="d-flex justify-content-center">
            <ButtonGroup className="me-2">
              <a target="_blank" href="https://youtu.be/0C8BBEt36wU">
                <Button variant="dark">Watch</Button>
              </a>
            </ButtonGroup>
            <ButtonGroup className="me-2">
              <a target="_blank" href="https://steve18624.itch.io/mindfuel">
                <Button variant="dark">Play</Button>
              </a>
            </ButtonGroup>
          </ButtonToolbar>
        </Card.Body>
      </Card>
    </div>
  );
}

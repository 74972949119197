import React from "react";
import PlanetSiegeHeader from "../components/PlanetSiege_Header";
import PlanetSiegeTable from "../components/PlanetSiege_Table";
import PlanetSiegeOverview from "../components/PlanetSiege_Overview";
import PlanetSiegeSummary from "../components/PlanetSiege_Summary";
import PlanetSiegeYouTubeTrailer from "../components/PlanetSiege_YouTubeTrailer";
import PlanetSiegeScreenshots from "../components/PlanetSiege_Screenshots";
import PlanetSiegeFooterLinks from "../components/PlanetSiege_FooterLinks";

const PlanetSiegePage = () => (
  <React.Fragment>
    <div className="game-background">
      <PlanetSiegeHeader />
      <PlanetSiegeOverview />
      <PlanetSiegeSummary />
      <PlanetSiegeYouTubeTrailer/>
      <PlanetSiegeScreenshots />
      <PlanetSiegeFooterLinks />
      {/* <PlanetSiegeTable/> */}
    </div>
  </React.Fragment>
);

export default PlanetSiegePage;

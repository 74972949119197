import { ReactElement } from "react";
import {
  Button,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Carousel,
  Container,
} from "react-bootstrap";
import "../App.scss";

const CarouselItems = [
  <Carousel.Item key="0">
    <img
      className="d-block w-100"
      src={require("../images/InItsPlaceLogo_1920x1080.webp")}
    />
  </Carousel.Item>,
  <Carousel.Item key="1">
    <img
      className="d-block w-100"
      src={require("../images/InItsPlace_02_InHome_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="2">
    <img
      className="d-block w-100"
      src={require("../images/InItsPlace_03_InHome_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="3" interval={12000}>
    <img
      className="d-block w-100"
      src={require("../images/InItsPlace_04_Menu_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="4" interval={12000}>
    <img
      className="d-block w-100"
      src={require("../images/InItsPlace_05_Gallery_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
    <Carousel.Item key="5" interval={12000}>
    <img
      className="d-block w-100"
      src={require("../images/InItsPlace_06_Gallery_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
];

export default function InItsPlaceCard(): ReactElement {
  return (
    <div>
      <Card className="card-custom">
        <Carousel>{CarouselItems}</Carousel>
        <Card.Body>
          <Card.Title>In Its Place</Card.Title>
          <Card.Text>
            InItsPlace is a cross platform hybrid AR/VR shopping experience that I created for the 2022 Microsoft Mixed Reality Dev Days Hackathon.  The application works with both the HoloLens and the Quest 2.
          </Card.Text>
          <Card.Text>
          I collaborated with a team of 4 across 3 time zones (EST -5, IST +5.5, NZST +12).  We were ranked in the top 5 of the Hackathon.
          </Card.Text>
          <Card.Text>
            InItsPlace allows consumers to view items in their actual home- allowing them to select items that match their color scheme/décor and test the fit.  It provides retailers a virtual showroom to display their goods.
          </Card.Text>
          <ButtonToolbar className="d-flex justify-content-center">
            <ButtonGroup className="me-2">
              <a target="_blank" href="https://youtu.be/_LbvfzDunKM">
                <Button variant="dark">Watch</Button>
              </a>
            </ButtonGroup>
            <ButtonGroup className="me-2">
              <a target="_blank" href="https://devpost.com/software/in-its-place">
                <Button variant="dark">Download</Button>
              </a>
            </ButtonGroup>
          </ButtonToolbar>
        </Card.Body>
      </Card>
    </div>
  );
}

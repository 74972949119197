import { ReactElement } from "react";
import { Button, Card, Carousel, ListGroup } from "react-bootstrap";

function IntermediateSkillsCard(): ReactElement {
  return (
    <div>
      <Card className="card-custom">
        <Card.Body>
          <Card.Title className="card-title-skills">Intermediate</Card.Title>
          <ListGroup variant="flush">
          <ListGroup.Item>Augmented Reality Design</ListGroup.Item>
          <ListGroup.Item>AI</ListGroup.Item>
            <ListGroup.Item>Blender</ListGroup.Item>
            <ListGroup.Item>Affinity Designer</ListGroup.Item>
            <ListGroup.Item>HTML/CSS/JS</ListGroup.Item>
            <ListGroup.Item>React</ListGroup.Item>
            <ListGroup.Item>Cloud Platforms (AWS, Azure)</ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </div>
  );
}

export default IntermediateSkillsCard;

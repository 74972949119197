import React from "react";
import { Row, Col, Container } from "react-bootstrap";

// import trailer1 from "https://youtu.be/iw4og4RUTt4";

const PlanetSiegeYouTubeTrailer = () => {
  return (
    // IMPORTANT class must be used on the image element not the containter (i.e. div)
      <div className="game-trailer-background">
        <div className="game-trailer">
          <h1>Trailer</h1>
          <div className="yt">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/iw4og4RUTt4?controls=0" frameBorder="0" allow="accelerometer; autoplay; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
      </div>
  );
};

export default PlanetSiegeYouTubeTrailer;

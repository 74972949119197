import React from "react";
import {
  Button,
  ButtonToolbar,
  ButtonGroup,
  Container,
  Row,
  Col
} from "react-bootstrap";

const PlanetSiegeFooterLinks = () => {
  return (
    <React.Fragment>
      <div className="game-footerlinks">
      <Container>
        <Row className="row justify-content-center">
          <Col className="col-md-3 col-6">
          <h3>Join Our Community</h3>
          </Col>
          <Col className="col-md-3 col-6">
          <h3>Buy Now</h3>
          </Col>
        </Row>
        <Row className="row justify-content-center">
        <Col className="col-md-3 col-6">
          <ButtonGroup className="me-2">
              <a target="_blank">
                <Button variant="primary">Discord</Button>
              </a>
            </ButtonGroup>
          </Col>
          <Col className="col-md-3 col-6">
          <ButtonGroup className="me-2">
              <a target="_blank">
                <Button variant="primary">Meta AppLab</Button>
              </a>
            </ButtonGroup>
          </Col>
        </Row>
          </Container>
      </div>
    </React.Fragment>
  );
};

export default PlanetSiegeFooterLinks;

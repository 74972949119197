import React from "react";
import { CardGroup, Container } from "react-bootstrap";
import InItsPlaceCard from "../cards/InItsPlaceCard.tsx";
import MindFuelCard from "../cards/MindFuelCard.tsx";
import MHHubCard from "../cards/MHHubCard.tsx";
import VRTBTCard from "../cards/VRTBTCard.tsx";
import SoleSearching from "../cards/SoleSearchingCard.tsx";
import NeighborhoodCard from "../cards/NeighborhoodCard.tsx";
import NetTacticsCard from "../cards/NetTacticsCard.tsx";
import VoidCourierCard from "../cards/VoidCourierCard.tsx";
import BuildBuddyCard from "../cards/BuildBuddyCard.tsx";

const cardsToDisplay = [
  <VRTBTCard key="0" />,
  <BuildBuddyCard key="1" />,
  <VoidCourierCard key="2" />,
  <InItsPlaceCard key="3" />,
  <MindFuelCard key="4" />,
  <MHHubCard key="5" />,
  <NetTacticsCard key="6" />,
  <SoleSearching key="7" />,
  <NeighborhoodCard key="8" />,
];

const Portfolio = () => {
  return (
    <React.Fragment>
      <section id="portfolio-section">
        <div>
          <h1>Portfolio</h1>
          <h6>This is some of my work.</h6>
          <Container>
            <CardGroup className="justify-content-md-center">
              {cardsToDisplay}
            </CardGroup>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Portfolio;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const PlanetSiegeOverview = () => {
  return (
    <React.Fragment>
      <div className="game-overview">
      <Container
          fluid
        >
          <Row
            className="game-row"
          >
            <Col md="4" className="game-overview-key-info">
            <h3>
            Genre
            </h3>
            <h6>
            Single Player, VR, FPS, Turn Based Tactics, Real Time Strategy
            </h6>
            <h3>
            Platforms
            </h3>
            <h6>
            Oculus Quest 2, Steam
            </h6>
            <h3>
            Release
            </h3>
            <h6>
            2024             (contact us about being an alpha tester)
            </h6>
            <h3>
            Age Rating
            </h3>
            <h6>
            Teen, Adult
            </h6>
            </Col>
            <Col md="1"></Col>
            <Col md="6" className="col center-block">
              <p>
              Planet Siege VR is a thrilling, first-person turn based tactics VR game set in a future, sci-fi universe where humanity has colonized the solar system and made significant technological advances; however, faster than light travel (FTL) remains elusive until the discovery of a unique mineral.
              </p>
              <p>
              Players take on the role of the leader of an expedition who must build and protect mining operations on an alien planet while fending off attacks from an opposing coalition. </p>
              <p>
              Players must make strategic decisions to manage resources and lead their squad of customizable soldiers through a variety of tactical battles against an assortment of invaders.
              </p>
              <p>
              With its rich, immersive gameplay, Planet Siege VR offers a unique and unforgettable gaming experience. Are you ready to lead your expedition to victory and take humanity to the next level?
              </p>
            </Col>
          </Row>
        </Container>
        </div>
    </React.Fragment>
  );
};

export default PlanetSiegeOverview;

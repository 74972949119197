import React from "react";
import { CardGroup, Container } from "react-bootstrap";
import AdvancedSkillsCard from "../cards/AdvancedSkillsCard.tsx";
import IntermediateSkillsCard from "../cards/IntermediateSkillsCard.tsx";
import BeginnerSkillsCard from "../cards/BeginnerSkillsCard.tsx";

const cardsToDisplay = [
  <AdvancedSkillsCard key="0" />,
  <IntermediateSkillsCard key="1" />,
  <BeginnerSkillsCard key="2" />,
];

const Skills = () => {
  return (
    <React.Fragment>
      <section id="skills-section">
        <div>
          <h1>Skills</h1>
          {/* <h6>This is some of my work.</h6> */}
          <Container>
            <CardGroup className="justify-content-md-center">
              {cardsToDisplay}
            </CardGroup>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Skills;

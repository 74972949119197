import { ReactElement } from "react";
import {
  Button,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Carousel,
  Container,
} from "react-bootstrap";
import "../App.scss";

const CarouselItems = [
  <Carousel.Item key="0">
    <img
      className="d-block w-100"
      src={require("../images/BuildBuddy_01.webp")}
    />
  </Carousel.Item>,
  <Carousel.Item key="1">
    <img
      className="d-block w-100"
      src={require("../images/BuildBuddy_02.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="2">
    <img
      className="d-block w-100"
      src={require("../images/BuildBuddy_03.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  // <Carousel.Item key="3" interval={12000}>
  //   <img
  //     className="d-block w-100"
  //     src={require("../images/InItsPlace_04_Menu_16x9.webp")}
  //     loading="lazy"
  //   />
  // </Carousel.Item>,
  // <Carousel.Item key="4" interval={12000}>
  //   <img
  //     className="d-block w-100"
  //     src={require("../images/InItsPlace_05_Gallery_16x9.webp")}
  //     loading="lazy"
  //   />
  // </Carousel.Item>,
  //   <Carousel.Item key="5" interval={12000}>
  //   <img
  //     className="d-block w-100"
  //     src={require("../images/InItsPlace_06_Gallery_16x9.webp")}
  //     loading="lazy"
  //   />
  // </Carousel.Item>,
];

export default function BuildBuddyCard(): ReactElement {
  return (
    <div>
      <Card className="card-custom">
        <Carousel>{CarouselItems}</Carousel>
        <Card.Body>
          <Card.Title>Build Buddy</Card.Title>
          <Card.Text>
          Follow step-by-step 3D furniture instructions directly in your real-world environment using Build Buddy, transforming the headache-inducing task of furniture assembly into a walk in the virtual park. Say goodby to squinting at cryptic paper instructions as you breeze through each step using your voice, hand swipe gestures, or finger pokes.
          </Card.Text>
          <Card.Text>
          We created the app for the 2024 Meta Quest Presence Hackathon.
          </Card.Text>
          <Card.Text>
          I collaborated with a team of 5 across 3 time zones (EST -5, GMT +2, IST +5.5, PST -8).
          </Card.Text>
          <ButtonToolbar className="d-flex justify-content-center">
            <ButtonGroup className="me-2">
              <a target="_blank" href="https://vimeo.com/945664778">
                <Button variant="dark">Watch</Button>
              </a>
            </ButtonGroup>
            <ButtonGroup className="me-2">
              <a target="_blank" href="https://devpost.com/software/furniture-assembly">
                <Button variant="dark">Download</Button>
              </a>
            </ButtonGroup>
          </ButtonToolbar>
        </Card.Body>
      </Card>
    </div>
  );
}

import { ReactElement } from "react";
import { Button, Card, Carousel, ListGroup } from "react-bootstrap";

function AdvancedSkillsCard(): ReactElement {
  return (
    <div>
      <Card className="card-custom">
        <Card.Body>
          <Card.Title className="card-title-skills">Advanced</Card.Title>
          <ListGroup variant="flush">
            <ListGroup.Item>Unity</ListGroup.Item>
            <ListGroup.Item>C#</ListGroup.Item>
            <ListGroup.Item>Virtual Reality Design</ListGroup.Item>
            <ListGroup.Item>3D Game Design</ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </div>
  );
}

export default AdvancedSkillsCard;

import { ReactElement } from "react";
import { Button, Card, ListGroup } from "react-bootstrap";

function BeginnerSkillsCard(): ReactElement {
  return (
    <div>
      <Card className="card-custom">
        <Card.Body>
          <Card.Title className="card-title-skills">Beginner</Card.Title>
          <ListGroup variant="flush">
            <ListGroup.Item>Animation</ListGroup.Item>
            <ListGroup.Item>Audio design</ListGroup.Item>
            <ListGroup.Item>Substance Painter</ListGroup.Item>
            <ListGroup.Item>Substance Designer</ListGroup.Item>
            <ListGroup.Item>Maya</ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </div>
  );
}

export default BeginnerSkillsCard;

import React from "react";
import { Row, Col, Container } from "react-bootstrap";
// import profilePic from "../images/ProfilePic.jpg";
import profilePic from "../images/ProfilePictureCroppedTransparentBackground.webp";
import profilePicBackground from "../images/ProfilePic_Banner_10_1600x500.webp";

const About = () => {
  return (
    <section id="home-section">
      <img src={profilePicBackground} className="about-banner" />
      <Container>
        <Row className="about-row">
          {/* <Col md="4"  > */}
          <Col md="auto">
            <img
              src={profilePic}
              className="about-profilepic"
              // style={{ maxHeight: "250px", maxWidth: "250px" }}
            />
          </Col>
          <Col
            md="8"
            // className="col align-self-center"
            // style={{ color:"white", backgroundColor: "rgba(0, 0, 0, 0.95)", padding: "20px", borderRadius: "15px"}}
            className="about-textbox"
          >
            <h2>Hi, I'm Steve.</h2>
            <p>
              I believe that Extended Reality (XR) will be a
              transformational technology in the coming decade. The hardware
              and software capabilities to create immersive virtual simulations, and
              mix reality with them, has grown exponentially in recent years.
            </p>
            <p>
              I want to be part of the action and I am looking to connect with like
              minded people.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;

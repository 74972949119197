import React from "react";
import {
  Navbar,
  Nav,
} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { NavHashLink } from "react-router-hash-link";

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

function NavBar() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto">
            <NavHashLink
              smooth
              to="/#home-section"
              scroll={(el) => scrollWithOffset(el)}
            >
              Home
            </NavHashLink>
            <NavHashLink
              smooth
              to="/#portfolio-section"
              scroll={(el) => scrollWithOffset(el)}
            >
              Portfolio
            </NavHashLink>
            <NavHashLink
              smooth
              to="/#skills-section"
              scroll={(el) => scrollWithOffset(el)}
            >
              Skills
            </NavHashLink>
            <NavHashLink smooth to="/#contact-section">
              Contact
            </NavHashLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;

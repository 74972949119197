import { ReactElement } from "react";
import { Button, Card, Carousel } from "react-bootstrap";
import "../App.scss";



const CarouselItems = [
  <Carousel.Item key="0">
    <img
      className="d-block w-100"
      src={require("../images/NetTactics_GameType_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="1">
    <img
      className="d-block w-100"
      src={require("../images/NetTactics_Lobby_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="2">
    <img
      className="d-block w-100"
      src={require("../images/NetTactics_Player1View_16x9.webp")}
      loading="lazy"
/>
  </Carousel.Item>,
  <Carousel.Item key="3">
    <img
      className="d-block w-100"
      src={require("../images/NetTactics_Player2View_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
];

export default function NetTacticsCard(): ReactElement {
  return (
    <div>
      <Card className="card-custom">
        <Carousel interval={5000}>{CarouselItems}</Carousel>
        <Card.Body>
          <Card.Title>Net Tactics</Card.Title>
          {/* <Card.Subtitle>(working title, in development)</Card.Subtitle> */}
          <Card.Text>
            Net Tactics is a 2-player turn based VR multiplayer game that I
            developed with a team member for the ETHGlobal 2022 Game Jam.
          </Card.Text>
          <Card.Text>
            Players perform 3 actions per turn (e.g. move, create a self
            projection, pick up grenades, throw a grenade). The goal is to
            damage the other player with grenades. The Player can move towards
            available grenades or use projections to conceal their true
            movements.
          </Card.Text>
          <Card.Text>
            Players join a server game (Azure hosted) or host games themselves.
            We envisioned integrating player avatar NFTs to target the Web3 game
            jam theme but did not complete this feature.
          </Card.Text>
          {/* <Button variant="dark">Go somewhere</Button> */}
        </Card.Body>
      </Card>
    </div>
  );
}

import React, { Component } from "react";
import { Button, Form, Row, Container, Modal } from "react-bootstrap";
// import API from '@aws-amplify/api';

import { post } from 'aws-amplify/api';

// import { API } from 'aws-amplify';


class FormSubmissionModal extends React.Component {
  constructor(props) {
    super(props);
  }

  closeModal = () => {
    window.location.reload(false);
  };

  // The show prop isn't needed for this specific implementation; however, I left it for future use cases
  render() {
    const renderModal = this.props.show ? (
      <React.Fragment>
        <Modal show={true}>
          <Modal.Dialog>
            <Modal.Header className="justify-content-md-center">
              <Modal.Title>Success!</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>Thank you for contacting me.</p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={this.closeModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </Modal>
      </React.Fragment>
    ) : null;
    // console.log("FormSubmissionModal executed.  Show: " + this.props.show);
    return <>{renderModal}</>;
  }
}

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: "",
      formSubmitted: false,
    };
  }

  onSubmitForm = async (e) => {
    console.log(
      "Form submitted. " +
        "Name: " +
        this.state.name +
        " Email: " +
        this.state.email +
        " Message: " +
        this.state.message
    );

    e.preventDefault();

    const formData = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };

    try {
      const apiName = 'apiContactForm';
      const path = '/contacts';

      // const response = await API.post(apiName, path, {
        const restOperation = post({apiName, path, options: {
        body: formData,
        }
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      console.log('Success:', response);
      this.setState({ formSubmitted: true });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const submittedModal = this.state.formSubmitted ? (
      <FormSubmissionModal show={this.state.formSubmitted} />
    ) : null;

    // console.log("Form main render");

    return (
      <React.Fragment>
        {submittedModal}
        {/* <FormSubmissionModal show={this.state.formSubmitted} /> */}
        <section id="contact-section">
          <Container>
            <h1>Contact</h1>
            <br></br>
            <Form onSubmit={this.onSubmitForm}>
              <Row md={2} className="justify-content-md-center">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Your Name</Form.Label>
                  <Form.Control
                    type="textarea"
                    rows={1}
                    placeholder="Enter name"
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Row>
              <Row md={2} className="justify-content-md-center">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Your Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Row>
              <Row md={2} className="justify-content-md-center">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Your Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter message"
                    value={this.state.message}
                    onChange={(e) => this.setState({ message: e.target.value })}
                  />
                </Form.Group>
              </Row>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ContactForm;

import React from "react";
import About from "../components/About.jsx";
import Portfolio from "../components/Portfolio.jsx";
import Skills from "../components/Skills.jsx";
import ContactForm from "../components/ContactForm.jsx";
import NavBar from "../NavBar";

const HomePage = () => {
  return (
    <React.Fragment>
      <NavBar />
      <About />
      <Portfolio />
      <Skills />
      <ContactForm />
    </React.Fragment>
  );
};

export default HomePage;

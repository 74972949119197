import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tactical from "../images/vrtbt_Msn_FZ_ForwardBase_01_021_2560x1440.webp";
import TowerDefense from "../images/vrtbt_009PlanetDefenseRoom_011_1920x1080.webp";

const PlanetSiegeSummary = () => {
  return (
    <React.Fragment>
      <div className="game-summary">
        <Container
          fluid
        >
          <Row
            className="game-row"
          >
            <Col md="6">
              <img src={Tactical} className="game-genre-img" />
            </Col>
            <Col md="1"></Col>
            <Col md="4" className="col center-block">
              <h2>Turn Based Tactics</h2>
              <li>
                Engage in turn-based tactical combat and use strategic thinking to lead your squad to victory.
              </li>
              <li>
                Command a squad of unique soldiers, recruit and customize your team, upgrade their abilities and equipment as you progress through the game.
              </li>
              <li>
              Take down enemies through the game's skillful shooting mechanics, abilities system, and variety of weapons.
              </li>
              {/* <li>
                With on-the-ground combat and direct control of your squad, you're right in the middle of the action. And with the added immersion of VR, every battle will feel intense and heart-pounding.
              </li> */}
            </Col>
          </Row>
          <Row
            className="game-row"
          >
            <Col md="4">
              <h2>Real Time Tower Defense</h2>
              <li>
                Manage your resources and make strategic decisions about mining and space defense unit deployments.
              </li>
              <li>
              Place a variety of unique defense units in strategic locations around the planet.  Make the right choices or get ready for ground combat.
              </li>
              <li>
                Decide which planetary defense units to upgrade to defend against the invaders' current attack profile.
              </li>
            </Col>
            <Col md="1"></Col>
            <Col md="6">
              <img src={TowerDefense} className="game-genre-img" loading="lazy"/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PlanetSiegeSummary;

import { ReactElement } from "react";
import {
  Button,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Carousel,
  Container,
} from "react-bootstrap";
import "../App.scss";

const CarouselItems = [
  <Carousel.Item key="0">
    <img
      className="d-block w-100"
      src={require("../images/VoidCourier_10.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="1">
    <img
      className="d-block w-100"
      src={require("../images/VoidCourier_02.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="2">
    <img
      className="d-block w-100"
      src={require("../images/VoidCourier_04.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="3" interval={12000}>
    <img
      className="d-block w-100"
      src={require("../images/VoidCourier_05.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="4" interval={12000}>
    <img
      className="d-block w-100"
      src={require("../images/VoidCourier_06.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
];

export default function VoidCourierCard(): ReactElement {
  return (
    <div>
      <Card className="card-custom">
        <Carousel>{CarouselItems}</Carousel>
        <Card.Body>
          <Card.Title>Void Courier</Card.Title>
          <Card.Text>
          Void Courier is a VR game that I created for VR Jam 2023.
          </Card.Text>
          <Card.Text>
          Inspired by "Portal," and "Portal 2," Void Courier introduces its own unique spin on the puzzle-solving genre. With complete immersion and intuitive controls, you'll find yourself deeply engaged in an experience that challenges your intellect and spatial awareness.
          </Card.Text>
          <ButtonToolbar className="d-flex justify-content-center">
            <ButtonGroup className="me-2">
              <a target="_blank" href="https://www.youtube.com/watch?v=UUziWDyZcxw">
                <Button variant="dark">Watch</Button>
              </a>
            </ButtonGroup>
            <ButtonGroup className="me-2">
              <a target="_blank" href="https://steve18624.itch.io/void-courier">
                <Button variant="dark">Play</Button>
              </a>
            </ButtonGroup>
          </ButtonToolbar>
        </Card.Body>
      </Card>
    </div>
  );
}

import { ReactElement } from "react";
import {
  Button,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Carousel,
  Container,
} from "react-bootstrap";
import "../App.scss";

const CarouselItems = [
  <Carousel.Item key="0">
    <img
      className="d-block w-100"
      src={require("../images/SoleSearching_shoe_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="1">
    <img
      className="d-block w-100"
      src={require("../images/SoleSearching_ballroom_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="2">
    <img
      className="d-block w-100"
      src={require("../images/SoleSearching_foot_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="3" interval={12000}>
    <img
      className="d-block w-100"
      src={require("../images/SoleSearching_shoes_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
];

export default function SoleSearchingCard(): ReactElement {
  return (
    <div>
      <Card className="card-custom">
        <Carousel>{CarouselItems}</Carousel>
        <Card.Body>
          <Card.Title>Sole Searching</Card.Title>
          <Card.Text>
            This was my Global Game Jam 2021 submission. I worked with two other
            team members on the VR game.
          </Card.Text>
          <Card.Text>
            You run the lost and found desk at the ball, and Cinderella isn't
            the only one missing her shoe. Can you get the shoes back to the
            nobles by midnight?
          </Card.Text>
          <ButtonToolbar className="d-flex justify-content-center">
            <ButtonGroup className="me-2">
              <a target="_blank" href="https://youtu.be/u7CDbErD7Q0">
                <Button variant="dark">Watch</Button>
              </a>
            </ButtonGroup>
            <ButtonGroup className="me-2">
              <a target="_blank" href="https://heyacfox.itch.io/sole-searching">
                <Button variant="dark">Play</Button>
              </a>
            </ButtonGroup>
          </ButtonToolbar>
        </Card.Body>
      </Card>
    </div>
  );
}

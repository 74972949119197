import { ReactElement } from "react";
import { Button, Card, Carousel } from "react-bootstrap";

const CarouselItems = [
  <Carousel.Item key="0">
    <img
      className="d-block w-100"
      src={require("../images/MHhub_login_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="1" interval={6000}>
    <img
      className="d-block w-100"
      src={require("../images/MHhub_Auth0_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="2">
    <img
      className="d-block w-100"
      src={require("../images/MHhub_vrview_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="3" interval={8000}>
    <img
      className="d-block w-100"
      src={require("../images/MHhub_phone_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
];

export default function MHHubCard(): ReactElement {
  return (
    <div>
      <Card className="card-custom">
        <Carousel>{CarouselItems}</Carousel>
        <Card.Body>
          <Card.Title>MHhub</Card.Title>
          <Card.Text>
            Mental Health (MH) Hub was a prototype that I developed to
            demonstrate the interaction between a native mobile application
            built with Unity and a web app with secure video conferencing.
          </Card.Text>
          <Card.Text>
            I used the Google Cardboard SDK and deployed the app on both Android
            and iOS. The web app was developed with React. Auth0 was used for
            security and authentication. For RTC (video conferencing), I used
            the Agora.io platform.
          </Card.Text>
          <Card.Text>
            The web app was deployed on AWS. The domain has been retired.
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

import { ReactElement } from "react";
import { Button, Card, Carousel } from "react-bootstrap";
import "../App.scss";

const CarouselItems = [
  <Carousel.Item key="0">
    <img
      className="d-block w-100"
      src={require("../images/Neighborhood_tp_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="1">
    <img
      className="d-block w-100"
      src={require("../images/Neighborhood_blender_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="2">
    <img
      className="d-block w-100"
      src={require("../images/Neighborhood_fp_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
];

export default function NeighborhoodCard(): ReactElement {
  return (
    <div>
      <Card className="card-custom">
        <Carousel interval={8500}>{CarouselItems}</Carousel>
        <Card.Body>
          <Card.Title>The Neighborhood</Card.Title>
          {/* <Card.Subtitle>(working title, in development)</Card.Subtitle> */}
          <Card.Text>
            One of my early projects. I tried to recreate my neighborhood as a
            virtual environment.
          </Card.Text>
          <Card.Text>
            My goal was to understand the limits of Unity and modeling software
            when it came to creating VR environments. I used Blender, Substance
            Painter, Substance Designer, and Unity.
          </Card.Text>
          {/* <Button variant="dark">Go somewhere</Button> */}
        </Card.Body>
      </Card>
    </div>
  );
}

import { ReactElement } from "react";
import { Button, ButtonToolbar, ButtonGroup, Card, Carousel } from "react-bootstrap";
import "../App.scss";
// import * as profilePic from "../images/vrtbt_title_16x9.webp";

// declare profilePic "../images/vrtbt_title_16x9.webp";

const CarouselItems = [
  <Carousel.Item key="0">
    <img
      className="d-block w-100"
      src={require("../images/vrtbt_009PlanetDefenseRoom_011_1920x1080.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="1">
    <img
      className="d-block w-100"
      src={require("../images/vrtbt_space_16x9.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="2">
    <img
      className="d-block w-100"
      src={require("../images/vrtbt_004SquadCreation_002_1920x1080.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
  <Carousel.Item key="3">
    <img
      className="d-block w-100"
      src={require("../images/vrtbt_008SkillTree_017_1920x1080.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
    <Carousel.Item key="4">
    <img
      className="d-block w-100"
      src={require("../images/vrtbt_Msn_FZ_ForwardBase_01_020_2560x1440.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
    <Carousel.Item key="5">
    <img
      className="d-block w-100"
      src={require("../images/vrtbt_Msn_FZ_ForwardBase_01_021_2560x1440.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
    <Carousel.Item key="6">
    <img
      className="d-block w-100"
      src={require("../images/vrtbt_Msn_DZ_MiningConvoyAmbush_01_010_1920x1080.webp")}
      loading="lazy"
    />
  </Carousel.Item>,
];

export default function VRTBTCard(): ReactElement {
  return (
    <div>
      <Card className="card-custom">
        <Carousel interval={7500}>{CarouselItems}</Carousel>
        <Card.Body>
          <Card.Title>Planet Siege</Card.Title>
          <Card.Subtitle>(in development)</Card.Subtitle>
          <Card.Text>
            Planet Siege is a first person turn based tactics and real time strategy game developed
            for the Oculus Quest platform.
          </Card.Text>
          <Card.Text>
Mount a planetary defense against the invaders
            in real-time by making strategic decisions about which space defense units to build
            and where to place them.  When your space defense fails, take the fight to the ground controlling multiple squad members and executing a tactical turn based response to the invaders.
          </Card.Text>
          <ButtonToolbar className="d-flex justify-content-center">
            {/* <ButtonGroup className="me-2">
              <a target="_blank" href="/VRTBT">
                <Button variant="dark">More</Button>
              </a>
            </ButtonGroup> */}
            {/* <ButtonGroup className="me-2">
              <a target="_blank" href="https://youtu.be/iw4og4RUTt4">
                <Button variant="dark">Watch</Button>
              </a>
            </ButtonGroup> */}
            <ButtonGroup className="me-2">
              <a target="_blank" href="./planetsiege">
                <Button variant="dark">Web Page</Button>
              </a>
            </ButtonGroup>
          </ButtonToolbar>
        </Card.Body>
      </Card>
    </div>
  );
}

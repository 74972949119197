import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image1 from "../images/vrtbt_space_16x9.webp";
import Image2 from "../images/vrtbt_004SquadCreation_002_1920x1080.webp";
import Image3 from "../images/vrtbt_Msn_FZ_ForwardBase_01_020_2560x1440.webp";
import Image4 from "../images/vrtbt_Msn_DZ_MiningConvoyAmbush_01_010_1920x1080.webp";
import Image5 from "../images/vrtbt_DesertMission_16x9.webp";

const PlanetSiegeScreenshots = () => {
  return (
    <React.Fragment>
      <div className="game-screenshots-background">
      <Container
        fluid
      >
        <div className="game-screenshots-container">
          <h1>Screenshots</h1>
          <Row
            className="row align-items-center"
          >
            <Col md="4">
              <img src={Image1} className="zoom" loading="lazy"/>
            </Col>
            <Col md="4">
              <img src={Image2} className="zoom" loading="lazy"/>
            </Col>
            <Col md="4">
              <img src={Image3} className="zoom" loading="lazy"/>
            </Col>
            <Col md="4">
              <img src={Image4} className="zoom" loading="lazy"/>
            </Col>
            <Col md="4">
              <img src={Image5} className="zoom" loading="lazy"/>
            </Col>
          </Row>
        </div>
        
      </Container>
      </div>
    </React.Fragment>
  );
};

export default PlanetSiegeScreenshots;

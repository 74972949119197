import React, { Component } from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PlanetSiegePage from "./pages/PlanetSiegePage";
import PageNotFound from "./pages/PageNotFound";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
        {/* <NavBar /> */}
          <div>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="PlanetSiege" element={<PlanetSiegePage />} />
              <Route path="PageNotFound" element={<PageNotFound />} />
            </Routes>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
